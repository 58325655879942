<template>
    <div class="pa-2 m-2">
        <bread-crumbs :items="items"></bread-crumbs> 
        <tabs-comp :tabs="tabs" />

        <v-row class="m-3">
            <div class="text-end">
                <b-button v-b-toggle.add_smstemp class="btn-sm btn btn-success">إضافة رسالة</b-button>
            </div>
            <v-data-table
                dense
                :headers="smsHeader"
                :items="$store.state.smsTemp"
                class="elevation-1 m-1"
                :items-per-page="10"
                :page.sync="notepage"
                width="100%"
                id="MainTable"
                hide-default-footer
                @page-count="pageCount = $event"
                fixed-header
            >
                <template v-slot:item="row">
                    <tr>
                        <td>{{row.item.message}}</td>
                        <td>{{row.item._msg_type}}</td>
                        <td>{{row.item._created_by}}</td>
                        <td>{{row.item._updated_by}}</td>
                        <td>{{row.item._sentat}}</td>
                        <td>
                            <v-btn class="mx-2" dark small color="blue" @click="getItem(row.item)" v-b-toggle.add_smstemp>
                                تعديل
                            </v-btn>
                        </td>
                    </tr>
                </template>
            </v-data-table>
        </v-row>
        <addSMSTemplate :sms="smsItem" />
    </div>
</template>
<script>
import axios from 'axios'
import addSMSTemplate from '@/components/addSMSTemplate.vue';
import BreadCrumbs from '@/components/breadCrumbs.vue';
export default{
    components: { addSMSTemplate, BreadCrumbs },
    name: 'SMS-Generator',
    data() {
        return {
            tabs: [
                {
                    index:0,name:'رسائل SMS',href:'/inventory/products', class: 'mytab1'
                },
                {
                    index:1,name:'رسائل Whatsapp',href:'/inventory/best-seller', class: 'mytab2'
                },
            ],
            smsItem: '',
            items: [
                // {
                //     text: this.$store.state.hometitle,
                //     disabled: false,
                //     to: '/home',
                // },
                {
                    text: 'قوالب الرسائل',
                    disabled: true,
                    to: '/sms',
                },
            ],
            smsHeader:[
                {
                    text: 'نص الرسالة',
                    align: 'center',
                    filterable: false,
                    sortable:true,
                    value: 'message',
                    style: "text-align:center;direction:ltr"
                },
                {
                    text: 'نوع الرسالة',
                    align: 'center',
                    filterable: false,
                    sortable:true,
                    value: '_msg_type',
                    style: "text-align:center;direction:ltr"
                },
                {
                    text: 'اضافة بواسطة',
                    align: 'center',
                    filterable: false,
                    sortable:true,
                    value: '_created_by',
                    style: "text-align:center;direction:ltr"
                },
                {
                    text: 'تعديل بواسطة',
                    align: 'center',
                    filterable: false,
                    sortable:true,
                    value: '_updated_by',
                    style: "text-align:center;direction:ltr"
                },
                {
                    text: 'وقت الارسال',
                    align: 'center',
                    filterable: false,
                    sortable:true,
                    value: '_sentat',
                    style: "text-align:center;"
                },
                {
                    text: 'الاجراءات',
                    align: 'center',
                    filterable: false,
                    sortable:true,
                    value: '',
                    style: "text-align:center;"
                },
            ],
            rowsRows:[
                
            ],
            notepage:0,
            pageCount:10
        }
    },
    methods: {
        getItem(item){
            this.smsItem = item; 
        },
        getAllSMS(){
            const post = new FormData();
            post.append("type","getSMSTemp");
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("auth",this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append("data[all]",'sss')
            axios.post(
                this.$store.state.SAMCOTEC.r_path , post
            )
            .then((res) => {
                // // console.log(res.data);
                this.$store.state.smsTemp = res.data.results.data;
            })
        },
    },
    created() {
        this.getAllSMS();
    },
    computed: {
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
    },
}
</script>